.buy-frame-col {
    width: 20rem;
    height: 22rem;
    flex-shrink: 0;
    border-radius: 23px;
    background: #FFF;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    margin-top: 2rem;
}

.buy-frame-p1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 80%;
    font-weight: 600;
    margin: -2rem 0 0 1.9rem;
}

.buy-frame-p2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-weight: 400;
    margin: 0 0 -2rem 3rem;
}

@media only screen and (max-width: 1520px){
    .buy-frame-col {
        width: 14rem;
        height: 20rem;
        margin-top: 2rem;
    }
    .buy-frame-p2 {
        margin: 0 auto -1rem auto;
        font-size: 1rem;
    }

}

.digital-canvas-container-jack {
    overflow: hidden; /* Ensure the slider doesn't show images outside the visible area */
    position: relative;
}

.digital-canvas-slider-jack {
    display: flex;
    transition: transform 0.5s ease;
}

.digital-canvas-moving-div-Yang {
    width: 60rem;
    height: 16rem;
    border-radius: 1.4rem;
    background: #FFF;
    margin-right: 5vw !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: transform .6s ease; /* 添加过渡效果 */
    transform: scale(1); /* 初始无缩放 */
}

.digital-canvas-moving-div-Yang:hover {
    transform: scale(1.02);
}

.product-reviews-text-yang {
    width: 24vw !important;
    padding: 2vw 2vw 0;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 1rem;
}

.product-reviews-logo-yang {
    width: 20vw !important;
    /*height: auto;*/
    /*max-height: 2rem;*/
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .m-digital-canvas-image-Yang {
        width: 10vw !important;
        margin: auto !important;
    }
}


@media only screen and (max-width: 920px) {
    .new-jack-textura-copy {
        margin-left: 4vw;
    }

    .digital-canvas-moving-div-Yang {
        width: 90vw;
        height: 20rem;
        border-radius: 1.4rem;
        background: #FFF;
        margin-right: 0vw !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .product-reviews-text-yang {
        width: 84vw !important;
        padding: 6vw;
        font-family: Proxima Nova Regular, sans-serif;
        font-size: 1rem;
    }

    .product-reviews-logo-yang {
        width: 84vw !important;
        /*height: auto;*/
        /*max-height: 2rem;*/
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;

        .m-digital-canvas-image-Yang {
            width: auto !important;
            margin: auto !important;
        }
    }

    .m-digi-arrows-yang {
        justify-content: center !important;
    }

    .magic-switching-yang {
        margin-top: 4rem !important;
        /*transform: translateX(-${activeIndex * 100}%),*/
        /*transition: transform 0.3s ease-out,*/
    }

    .col-no {
        display: none;
    }

    .col-active {
        display: block;
    }
}

.product-details-image {
    width: 100%;
    height: 100%;
    background-image: url("./logos/digital-install2.png");
    background-size: cover;
}

.product-access-support-button {
    border: 2px solid white;
}

.product-price-klarna {
    color: #696969;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0 0.5rem;
}
